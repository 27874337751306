<template>
  <div class="w750">
    <router-view  v-slot="{ Component, route }">
      <keep-alive>
        <component :is="Component" :key="route.fullPath" v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <transition name="router-transition" mode="out-in">
        <!--:include="keepAliveList"-->
        <component :is="Component" :key="route.fullPath" v-if="!$route.meta.keepAlive"/>
      </transition>
    </router-view>
    <!--<footer-nav v-if="$route.meta.footShow" />-->
  </div>
</template>

<script>
import FooterNav from './components/footer/FooterNav'
import { getLastSegment, isAlipayOrWechat, urlToObj } from '@/utils'
import AliPayMixin from '@/views/mixins/AliPay'

export default {
  name: 'App',
  mixins: [AliPayMixin],
  components: {
    FooterNav
  },

  data () {
    return {
      keepAliveList: [],
      merchId: ''
    }
  },

  created () {
    // this.merchId = getLastSegment(window.location.href) // getUrlParam('merch')
    // this.$store.dispatch('GetBusinessInfo', this.merchId)

    this.handleNavigator()
  },

  mounted () {
    // this.form.code = this.getUrlParam('auth_code')
    // this.handleNavigator()
  },

  methods: {
    handleNavigator () {
      const userAgent = isAlipayOrWechat()
      const status = !(userAgent === 'alipay' || userAgent === 'wechat' || userAgent === 'unionPay')
      if (!status) {
        if (userAgent === 'alipay') {
          /* if (!this.form.code) {
            this.getAliCode()
          } else {
            alert(JSON.stringify(this.form))
          } */
        } else if (userAgent === 'wechat') {

        } else if (userAgent === 'unionPay') {

        } else {
          this.$router.replace({
            path: '/remind'
          })
        }
      } else {
        this.$router.replace({
          path: '/remind'
        })
      }
    }
  },

  watch: {
    $route (to, from) {
      document.body.scrollTop = 0
      if (this.$route.name == null) {
        this.$router.replace({
          path: '/404'
        })
      }
    }
  }
}
</script>

<style lang="scss">
  @import './styles/common.scss';
  @import './styles/mixin.scss';
  @import './styles/coverVantStyle';

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .router-transition-enter-active {
    transition: all .3s;
  }
  .router-transition-leave-active {
    transition: all .3s;
  }
  .router-transition-enter, .router-transition-leave-to {
    opacity: 0;
    transform: translateX(50px);
  }
  /*.router-fade-enter-active, .router-fade-leave-active {
    transition: opacity .1s;
  }
  .router-fade-enter, .router-fade-leave-active {
    opacity: 0;
  }
  .fadeIn-enter{
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: all 0.1s linear;
  }
  .fadeIn-leave-active {
    transition: all 0.1s linear;
    opacity: 0;
  }*/
</style>
