import moment from 'moment'
import { Toast } from 'vant'
import defaultSettings from '@/settings'

const title = defaultSettings.title || '固始高铁'

/** 获取页面title */
export default function getPageTitle (key) {
  if (key) {
    return `${title} - ${key}`
  }
  return `${title}`
}

/** 节流函数 */
// 节流函数的原理，我们对某个函数进行节流，他会返回一个新的函数，新的函数会延迟执行我们要节流的这个函数；我们返回的函数反复被调用，不会总是触发我们的func函数；
export function debounce (func, delay) {
  let timer

  return function (...args) { // 我们调用一个函数，他会返回一个参数；我们拿到这个参数；
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => { // 定义延时函数；
      func.apply(this, args) // 箭头函数中的this指向符集作用域；对象中有属性，方法，但并没有this
    }, delay)
  }
}

export const setLocalStorage = (key, value) => {
  if (!key) return
  let val = value
  if (typeof value !== 'string') {
    val = JSON.stringify(value)
  }
  window.localStorage.setItem(key, val)
}

export const getLocalStorage = (key) => {
  if (!key) return
  return window.localStorage.getItem(key)
}

export const removeLocalStorage = (key) => {
  if (!key) return
  window.localStorage.removeItem(key)
}

export const setSessionStorage = (key, value) => {
  if (!key) return
  let val = value
  if (typeof value !== 'string') {
    val = JSON.stringify(value)
  }
  window.sessionStorage.setItem(key, val)
}

export const getSessionStorage = (key) => {
  if (!key) return
  return window.sessionStorage.getItem(key)
}

export const removeSessionStorage = (key) => {
  if (!key) return
  window.sessionStorage.removeItem(key)
}

export function guid () {
  /**
   * @return {string}
   */
  function S4 () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
}

/**
 * 检测字符串是否为空
 * @param s
 * @returns {boolean}
 */
export function isEmpty (s) {
  if (typeof (s) === 'undefined') return true
  if (s === null) return true
  return s === ''
}

/**
 * 判断是否是手机号
 * @param mobile
 * @returns {boolean}
 */
const isMobile = (mobile) => {
  const reg = /^((1[3,4,5,6,7,8,9]))\d{9}$/
  const re = new RegExp(reg)
  return re.test(mobile)
}

export { isMobile }

/**
 * 表单手机号验证过程
 * @param mobile  手机号
 * @returns {boolean}  为true进行下一步
 */
export function validMobile (mobile) {
  if (isEmpty(mobile)) {
    Toast({
      message: '请您输入手机号',
      position: 'bottom'
    })
    return false
  }
  if (!isMobile(mobile)) {
    Toast({
      message: '请您输入正确的手机号',
      position: 'bottom'
    })
    return false
  }
  return true
}

export function deviceType () {
  const deviceIsWindowsPhone = navigator.userAgent.indexOf('Windows Phone') >= 0
  if (navigator.userAgent.indexOf('Windows Phone') >= 0) {
    return 'deviceIsWindowsPhone'
  } else if (navigator.userAgent.indexOf('Android') > 0 && !deviceIsWindowsPhone) {
    return 'deviceIsAndroid'
  } else if (/iP(ad|hone|od)/.test(navigator.userAgent) && !deviceIsWindowsPhone) {
    return 'deviceIsIOS'
  }
}

/**
* 格式化年月日
* */
export function formatDateTime (date, type) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()

  let str = ''
  if (type === 'time') {
    str = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  } else if (type === 'datetime') {
    str = `${year}-${month}-${day} ${hours}:${minutes}`
  } else if (type === 'date') {
    str = year + '-' + month + '-' + day
  }
  return str
}

/** 身份证校验 */
export function checkIdCard (val) {
  const reg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/
  return reg.test(val)
}

/** 手机号校验 */
export function checkPhone (val) {
  const reg = /^(1[3-9])\d{9}$/
  return reg.test(val)
}

/** 校验 null '' undefined */
export function checkNull (val) {
  return !(val === '' || val === null || typeof val === 'undefined')
}

/** 序列化参数 */
export function qsFunction (params) {
  let withArgs = ''
  for (const key in params) {
    let value = params[key]
    if (value instanceof Array || value.toString() === '[object Object]') {
      value = JSON.stringify(value)
    }
    withArgs += key + '=' + value + '&'
  }
  return withArgs.substring(0, withArgs.length - 1)
}

/** 是否是微信 */
export function isWeiXinScan () {
  const ua = navigator.userAgent.toLowerCase()
  // eslint-disable-next-line eqeqeq
  return ua.indexOf('micromessenger') != -1
}

/** 截取参数 */
export function urlToObj (str) {
  var obj = {}
  var arr1 = str.split('?')
  var arr2 = arr1[1].split('&')
  for (var i = 0; i < arr2.length; i++) {
    var res = arr2[i].split('=')
    obj[res[0]] = res[1]
  }
  return obj
}

/** 拼接字符串 */
export function urlAppendQuery (url, param) {
  if (!param) {
    return url
  }
  let queryString = ''
  for (const key in param) {
    if ({}.hasOwnProperty.call(param, key)) {
      if (param[key] === false || param[key] === 0 || param[key]) {
        queryString += `&${key}=${encodeURIComponent(param[key])}`
      }
    }
  }
  if (queryString) {
    return `${url}?${queryString.substring(1)}`
  }
  return url
}

/** 格式化时间 */
export function formatMD (val) {
  if (!val) return ''
  return moment(val).format('MM月DD日')
}

/** 是微信 */
export function isWeixin () {
  const ua = window.navigator.userAgent.toLowerCase()
  return ua.match(/MicroMessenger/i) === 'micromessenger'
}

/** 是支付宝 */
export function isAlipayClient () {
  return navigator.userAgent.indexOf('AlipayClient') > -1
}

export function isAlipayOrWechat () {
  const ua = window.navigator.userAgent.toLowerCase()

  if (ua.match(/Alipay/i) == 'alipay') {
    return 'alipay'
  } else if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return 'wechat'
  } else if (ua.indexOf('unionpay') !== -1) {
    return 'unionPay'
  } else {
    return ''
  }
}

/** 获取对象val */
export function getUrlParam (name) {
  const url = window.location.href
  const queryString = url.split('?')[1]
  if (!queryString) return null
  const params = queryString.split('&')
  for (const param of params) {
    const [key, value] = param.split('=')
    if (key === name) {
      return decodeURIComponent(value)
    }
  }
  return null
}

export function getLastSegment (url) {
  const segments = url.split('/')
  return segments.pop() || ''
}
